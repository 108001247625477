import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 登 录 》

/**
 * 获取短信验证码
*/
export function getSmsCode(parameter) {
  return axios({
    url: BASE_URL + '/sms_code',
    method: 'post',
    data: parameter
  })
}

/**
 * 登录
*/
export function login(parameter) {
  return axios({
    url: BASE_URL + '/login',
    method: 'post',
    data: parameter
  })
}

/**
 * 退出登录
*/
export function logout(parameter) {
  return axios({
    url: BASE_URL + '/logout',
    method: 'post',
    data: parameter
  })
}

/**
 * 获取用户信息
*/
export function userInfo(parameter) {
  return axios({
    url: BASE_URL + '/me',
    method: 'get',
    params: parameter
  })
}
